"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locales_short = exports.locales = void 0;

var _constructors = require("./constructors.js");

var _translations = _interopRequireDefault(require("../translations/pl_PL/translations.json"));

var _translations2 = _interopRequireDefault(require("../translations/en_GB/translations.json"));

var _translations3 = _interopRequireDefault(require("../translations/en/translations.json"));

var _translations4 = _interopRequireDefault(require("../translations/en_US/translations.json"));

var _translations5 = _interopRequireDefault(require("../translations/en_AU/translations.json"));

var _translations6 = _interopRequireDefault(require("../translations/es_MX/translations.json"));

var _translations7 = _interopRequireDefault(require("../translations/de_DE/translations.json"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var locales = new _constructors.Locales([{
  code: 'en',
  url: 'en',
  language: 'en',
  country: 'en',
  contentLanguage: 'en',
  nativeLanguageName: 'English',
  nativeCountryName: 'Europe',
  translation: _translations3["default"]
}, {
  code: 'en_GB',
  url: 'uk',
  language: 'en',
  country: 'gb',
  contentLanguage: 'en-GB',
  nativeLanguageName: 'English',
  nativeCountryName: 'United Kingdom',
  translation: _translations2["default"]
}, {
  code: 'en_US',
  url: 'us',
  language: 'en',
  country: 'us',
  contentLanguage: 'en-US',
  nativeLanguageName: 'English',
  nativeCountryName: 'United States',
  translation: _translations4["default"]
}, {
  code: 'en_AU',
  url: 'au',
  language: 'en',
  country: 'au',
  contentLanguage: 'en-AU',
  nativeLanguageName: 'English',
  nativeCountryName: 'Australia',
  translation: _translations5["default"]
}, {
  code: 'pl_PL',
  url: 'pl',
  language: 'pl',
  country: 'pl',
  contentLanguage: 'pl-PL',
  nativeLanguageName: 'Polski',
  nativeCountryName: 'Polska',
  translation: _translations["default"]
}, {
  code: 'es_MX',
  url: 'mx',
  language: 'es',
  country: 'mx',
  contentLanguage: 'es-MX',
  nativeLanguageName: 'Español',
  nativeCountryName: 'México',
  translation: _translations6["default"]
}, {
  code: 'de_DE',
  url: 'de',
  language: 'de',
  country: 'de',
  contentLanguage: 'de-DE',
  nativeLanguageName: 'Deutsch',
  nativeCountryName: 'Deutschland',
  translation: _translations7["default"]
}]);
exports.locales = locales;
var locales_short = {
  pl: _translations["default"],
  mx: _translations6["default"],
  gb: _translations2["default"],
  en: _translations3["default"],
  us: _translations4["default"],
  au: _translations5["default"],
  de: _translations7["default"]
};
exports.locales_short = locales_short;